import { get, post, request } from '@/util/http'
// import { request } from '@/util/http'

export const loginByUsername = (username, password) =>
  post('/login', { username, password })

export const getUserInfo = () => get('/user/getUserInfo')

export const refreshToken = () => post('/user/refesh')

export const getTopMenu = () => get('/user/getTopMenu')

export const sendLogs = (list) => post('/user/logout', list)

// export const getAreaPage = (channel) => get('/user/areaPage/getAreaPage', channel)

export const getAreaPage = (channel) => {
  return request({
    url: '/user/areaPage/getAreaPage',
    method: 'get',
    params: {
      channel
    }
  })
}

export const getNumberDetails = (channel) => {
  return request({
    url: '/user/areaPage/getNumberDetails',
    method: 'get',
    params: {
      channel
    }
  })
}

export const getPunchTheClockList = (channel) => {
  return request({
    url: '/user/areaPage/getPunchTheClockList',
    method: 'get',
    params: {
      channel
    }
  })
}

export const getDataAbnormalLog = (channel) => {
  return request({
    url: '/user/areaPage/getDataAbnormalLog',
    method: 'get',
    params: {
      channel
    }
  })
}

export const getDate = (item) => {
  return request({
    url: '/healthCommission/getDate',
    method: 'post',
    data: item
  })
}

export const userInfo = (item) => {
  return request({
    url: '/healthCommission/userInfo',
    method: 'post',
    data: item
  })
}

export const recentBreath = (item) => {
  return request({
    url: '/healthCommission/recentBreath',
    method: 'post',
    data: item
  })
}

export const getAvgByMonthPage = (idCard, wxUserIdData, month, index) => {
  return request({
    url: '/healthCommission/getAvgByMonthPage',
    method: 'get',
    params: {
      idCard,
      wxUserIdData,
      month,
      index
    }
  })
}
